import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Blog from '../../components/blogList';
import SEO from '../../components/seo';

function BlogPage({ data, location }) {
  const { edges: posts } = data.allMdx;
  return (
    <Layout>
      <SEO
        title="The mindthedoc blog"
        description="Follow the mindthedoc Blog and stay up to date with the latest news and learn about new product features."
        path={location.pathname}
      />
      <Blog posts={posts} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query blogIndex {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          id
          excerpt(pruneLength: 200)
          slug
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            tags
            author_name
            author_image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default BlogPage;
